@font-face {
    font-family:"roc-grotesk";
    src:url("https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }

@font-face {
    font-family:"roc-grotesk-wide";
    src:url("https://use.typekit.net/af/c9606e/00000000000000007735b7cd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/c9606e/00000000000000007735b7cd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/c9606e/00000000000000007735b7cd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
    }

@font-face {
    font-family:"flood-std";
    src:url("https://use.typekit.net/af/6da923/000000000000000000012fc3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/6da923/000000000000000000012fc3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/6da923/000000000000000000012fc3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }

    body{
        width: 100%;
    }

    * {
        box-sizing: border-box;
    }


p {
    font-family: 'roc-grotesk';
    font-style: normal;
    font-weight: 400;
}

h1, h2{
    font-family: 'roc-grotesk-wide';
    font-style: normal;
    font-weight: 750;
}

.logo-container{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: calc(2 / 10 * 100%);
    margin-bottom: 20px;
}

.home-icon-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 3rem;
    width: 100%;
}

.insta-icon-home, .twitter-icon-home{
    font-size: 2rem;
    color: black;
}

.twitter-icon-home{
    margin-right: 1rem;
}

.twitter-icon-bio{
    margin-right: 1rem;
}

.icon-container{
    width: 4rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 2rem;
}

.artist-link{
    display: block;
    padding-top: calc(9 / 6 * 100%);
}

.app-outer-wrapper{
    margin: 0 auto;
    max-width: 1700px;
}

.bio-gallery-container img{
    max-width: 100%;
}

.scroll-sticker{
    width: 362px;
    height: 250.27px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 390px;
    left: 666px;
}

.title-desktop{
    font-size: 160px;
    color: black;
    display: table-caption;
    margin: 47px 20px 32px 20px;
    width: 100%;
    line-height: 79%;
}

.title-tablet-white{
    font-size: 84px;
    color: white;
    display: table-caption;
    margin: 178px 20px 32px 20px;
    line-height: 79%;
}

.title-mobile-white{
    font-size: 46px;
    color: white;
    display: table-caption;
    margin: 36px 12px 22px 12px;
    line-height: 79%;
}

.eye-part{
    background-image: url('../animations/Assets/eye_part.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    list-style: none;
    width: 50%;
    height: 912px;
    margin-bottom: 150px;
    position: relative;
    display: inline-block;
}

.eye{
    background-image: url('../animations/Assets/eye.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    bottom: 115px;
    left: 50%;
    transform: translate(-50%);
    width: 55%;
    height: 25%;
}

.fade-appear,
.fade-enter{
    opacity: 0;
    z-index: 1;
}

.fade-appear-active, .fade-enter.fade-enter-active{
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit{
    opacity: 1;
}

.fade-exit.fade-exit-active{
    opacity: 0;
    transition: opacity 150ms linear;
}

.bio-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.image-container:hover .static-label{
    opacity: 0;
}
